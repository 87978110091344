import React from "react"
import Glider from "react-glider"
import { InView } from "react-intersection-observer"
import ReactPlayer from "react-player/lazy"

import { withProductImage } from "./withProductImage"
import { Image } from "../../Image/Image"
import { Desktop, Mobile, Close, Icon, Popup, StyledImage } from "./ProductImageStyles"
import { ProductImageNav } from "./Nav/ProductImageNav"

interface Props {
  active: boolean
  images: Array<any>
  setActive: any
  title: string
  zoomed: boolean
  activeImage: number
  setActiveImage: any
}

export const ProductImage = withProductImage(({ active, images, setActive, title, zoomed, activeImage, setActiveImage }: Props) => {
  const popupRef = React.useRef(null)

  const getHighPixelVideoUrl = (sources, mobile?) => {
    let url = sources?.find(source => (source.height === mobile ? 480 : 1080 && source.format === "mp4"))?.url

    // get any valid one as a default option
    if (!url) {
      url = sources?.find(source => source.height && source.format === "mp4")?.url
    }

    return url
  }

  return zoomed ? (
    <>
      <ProductImageNav activeImage={activeImage} images={images} zoomed={zoomed} active={active} />
      <Popup active={active} ref={popupRef}>
        <>
          {images.map(({ altText, id, src, mediaContentType, sources }, i) => (
            <InView
              key={i}
              root={popupRef.current}
              onChange={inView => {
                if (inView) {
                  setActiveImage(i)
                }
              }}
            >
              {({ inView, ref }) => (
                <StyledImage onClick={() => setActive(false)} index={i} zoomed ref={ref}>
                  {mediaContentType === "IMAGE" ? (
                    <Image alt={altText ? altText : `${title} product image`} ratio={`2/3`} src={src} />
                  ) : mediaContentType === "VIDEO" ? (
                    <ReactPlayer url={getHighPixelVideoUrl(sources)} playing={active && inView} height="auto" muted loop />
                  ) : null}
                </StyledImage>
              )}
            </InView>
          ))}
          <Close onClick={() => setActive(false)}>
            <Icon />
          </Close>
        </>
      </Popup>
    </>
  ) : (
    <>
      <ProductImageNav activeImage={activeImage} images={images} zoomed={zoomed} active={!active} />
      <Desktop>
        {images.map(({ altText, id, src, mediaContentType, sources }, i) => (
          <InView
            key={i}
            onChange={inView => {
              if (inView) {
                setActiveImage(i)
              }
            }}
          >
            {({ inView, ref }) => (
              <StyledImage onClick={() => setActive(true)} index={i} ref={ref}>
                {mediaContentType === "IMAGE" ? (
                  <Image alt={altText ? altText : `${title} product image`} ratio={`2/3`} src={src} />
                ) : mediaContentType === "VIDEO" ? (
                  <ReactPlayer url={getHighPixelVideoUrl(sources)} playing={!active && inView} height="auto" muted loop />
                ) : null}
              </StyledImage>
            )}
          </InView>
        ))}
      </Desktop>

      <Mobile>
        <Glider draggable duration={0.3} hasDots={images.length > 1} scrollLock slidesToShow={1} slidesToScroll={1}>
          {images.map(({ altText, src, mediaContentType, sources }, i) =>
            mediaContentType === "IMAGE" ? (
              <Image
                key={i}
                alt={altText ? altText : `${title} product image`}
                onClick={() => setActive(true)}
                ratio={`2/3`}
                src={src}
                playing={true}
              />
            ) : mediaContentType === "VIDEO" ? (
              <ReactPlayer url={getHighPixelVideoUrl(sources)} playing={!active} width="100%" height="100%" playsinline muted loop />
            ) : null
          )}
        </Glider>
      </Mobile>
    </>
  )
})
