import React from "react"

import { useCore } from "../../../hooks/useCore"
import { useNavigation } from "../../../hooks/useNavigation"

export const withProductContentLinks = Component => ({ name = `ProductContentLinks`, setBooking, setContent, setPopup }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const {
    navigation: { products },
  } = useNavigation()

  const handlePopup = content => {
    setPopup(true)
    setContent({ accordions: content.accordions, content: content.content, title: content.title })
  }

  Component.displayName = name
  return <Component handlePopup={handlePopup} isBrowser={isBrowser} navigation={products} setBooking={setBooking} />
}
