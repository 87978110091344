import React from "react"

import { SearchItem } from "../../Search/Item/SearchItem"
import { Row } from "./ProductUpsellStyles"

interface Props {
  products: Array<any>
}

export const ProductUpsellRecommendations = ({ products }: Props) =>
  products?.length > 0 ? (
    <Row scrollHidden={`true`}>
      {products?.slice(0, 3).map((product, index) => (
        <SearchItem key={product.handle} index={index} list={`Product Recommendations`} product={product} width={`1/3`} screenWidth />
      ))}
    </Row>
  ) : null
