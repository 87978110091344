import React from "react"

import { useLanguage } from "../../../hooks/useLanguage"

export const withProductFormQuantity = Component => ({ name = `ProductFormQuantity`, hidden, handleQuantityChange, quantity }) => {
  const locales = useLanguage(`cart`)

  Component.displayName = name
  return <Component hidden={hidden} handleQuantityChange={handleQuantityChange} locales={locales} quantity={quantity} />
}
