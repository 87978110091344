import React from "react"

import { withProductFormAttributes } from "./withProductFormAttributes"
import { Date } from "../../Styled/Date"
import { Textarea } from "../../Styled/Textarea"
import { Attribute, Attributes, StyledLabel, StyledAttributeInput } from "./ProductFormStyles"

interface Props {
  attributes: any
  customAttributes: any
  handleAttributeChange: any
  handleize: any
}

export const ProductFormAttributes = withProductFormAttributes(
  ({ attributes, customAttributes, handleAttributeChange, handleize }: Props) =>
    attributes?.length > 0 && (
      <Attributes>
        {attributes.map(({ label, maxLength, placeholder, type, minDate }) => (
          <Attribute key={handleize(label)}>
            <StyledLabel>{`${label}`}</StyledLabel>
            {(() => {
              switch (type) {
                case "date":
                  return (
                    <Date
                      name={handleize(label)}
                      type="date"
                      min={minDate ?? null}
                      onChange={handleAttributeChange}
                      value={customAttributes[handleize(label)] || ``}
                    />
                  )
                  break
                case "textarea":
                  return (
                    <Textarea
                      maxLength={maxLength}
                      name={handleize(label)}
                      placeholder={placeholder}
                      onChange={handleAttributeChange}
                      value={customAttributes[handleize(label)] || ``}
                    />
                  )
                  break
                default:
                  return (
                    <StyledAttributeInput
                      name={handleize(label)}
                      placeholder={placeholder}
                      onChange={handleAttributeChange}
                      value={customAttributes[handleize(label)] || ``}
                    />
                  )
              }
            })()}
          </Attribute>
        ))}
      </Attributes>
    )
)
