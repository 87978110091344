import styled from "@emotion/styled"
import tw from "twin.macro"

export const Tabs = styled.nav`
  ${tw`flex items-center justify-start md:justify-center overflow-y-hidden overflow-x-auto md:overflow-x-hidden ml-1-2 md:-mx-1-6`}
  ${props => (props.end ? tw`md:justify-end` : ``)}
`
export const Tab = styled.button`
  ${tw`text-center text-base tracking-wide leading-looser md:px-2 whitespace-no-wrap focus:outline-none transition-all duration-200`}
  ${props => (props.active ? tw`text-dark border-dark` : tw`text-grey-darker border-grey hover:text-dark hover:border-grey-darker`)}
  ${props => (props.first ? tw`pl-0` : tw`pl-4`)}
  ${props => (props.border ? tw`border-t pt-0-8` : ``)}
  ${props => (props.disabled ? tw`pointer-events-none` : ``)}
  ${props => (props.small ? tw`text-sm` : ``)}
  ${props => (props.fill ? tw`flex-1 md:flex-none px-2` : ``)}
`
