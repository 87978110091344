import React from "react"

import { Product } from "../../../../types"

import { withProductContentRushFee } from "./withProductContentRushFee"
import { Popup } from "../../Modals/Popup/Popup"
import { ProductContentRushCalculator } from "./ProductContentRushCalculator"
import { P } from "../../Styled/Text"
import { RushFeeContent, StyledH5, StyledP } from "./ProductContentStyles"

interface Props {
  active: boolean
  locales: any
  localeContent: any
  product: Product
  rushFeeProduct: Product
  setRushFee: any
  setTimeline: any
  setWeeks: any
  status: any
  timeline: any
  trackedTimeline: any
  updateAddOn: any
}

export const ProductContentRushFee = withProductContentRushFee(
  ({
    active,
    locales,
    localeContent,
    product,
    rushFeeProduct,
    setRushFee,
    setTimeline,
    setWeeks,
    status,
    timeline,
    trackedTimeline,
    updateAddOn,
  }: Props) => (
    <Popup active={active} setActive={setRushFee}>
      <RushFeeContent>
        <StyledH5>
          {status === `Within` ? `${rushFeeProduct?.title}: ${rushFeeProduct?.variants[0]?.priceV2?.local || `—`}` : locales[`${localeContent}Title`]}
        </StyledH5>
        <P>{locales[`${localeContent}Description`]}</P>

        <ProductContentRushCalculator
          buttonText={locales[`${localeContent}Button`]}
          disabledButtonText={locales[`rushFeeCalculatorBeforeButton`]}
          rushFeeProduct={rushFeeProduct}
          setRushFee={setRushFee}
          setTimeline={setTimeline}
          setWeeks={setWeeks}
          status={status}
          timeline={timeline}
          trackedTimeline={trackedTimeline}
          updateAddOn={updateAddOn}
        />

        {locales[`${localeContent}Note`] ? <StyledP>{locales[`${localeContent}Note`].replace(`{product}`, product.title)}</StyledP> : null}
      </RushFeeContent>
    </Popup>
  )
)
