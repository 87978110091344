import React from "react"

import { useShopify } from "../../../hooks/useShopify"

export const withProductUpsellRelated = Component => ({ name = `ProductUpsellRelated`, handles }) => {
  const { excludeHidden, useProducts } = useShopify()

  const items = useProducts({ handles, firstImages: 2, firstMedia: 0, firstVariants: 1 })
  const products = excludeHidden(items).slice(0, 3)

  Component.displayName = name
  return <Component products={products} />
}
