import React from "react"

import { Product } from "../../../../types"

import { withProductFormAddOns } from "./withProductFormAddOns"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Money } from "../../Money"
import { Icon, AddOns, AddOn, Label, Trigger } from "./ProductFormStyles"

interface Props {
  active: boolean
  locales: any
  products: Array<Product>
  productType: string
  setActive: any
  updateAddOn: any
  wholesale: boolean
  updateRushAndAddon: any
  checkedAddOns: string[]
  rushCheckDisabler: boolean
}

export const ProductFormAddOns = withProductFormAddOns(
  ({ active, locales, products, productType, setActive, wholesale, updateRushAndAddon, checkedAddOns, rushCheckDisabler }: Props) =>
    products?.length > 0 ? (
      <>
        <Trigger className={`group`} onClick={() => setActive(!active)}>
          <Label>{`${locales.addOns}${!wholesale ? ` ${productType}` : ""}`} </Label>
          <Icon active={active ? `active` : undefined} />
        </Trigger>

        <AddOns active={active ? `active` : undefined}>
          {products.map((item, i) => (
            <AddOn key={i} last={i + 1 === item.length}>
              <Checkbox
                disable={item?.handle === "rush-order-under-12-weeks" ? rushCheckDisabler : false}
                externalState={checkedAddOns?.includes(item?.handle)}
                onChange={() => updateRushAndAddon(item)}
              >
                <span>{item.title} </span>
                <Money amount={item?.variants?.[0]?.priceV2} />
              </Checkbox>
            </AddOn>
          ))}
        </AddOns>
      </>
    ) : null
)
