import React, { useState } from "react"

import { useLanguage } from "../../../hooks/useLanguage"

export const withProductContent = Component => ({ name = `ProductContent`, product, content, template }) => {
  const replacements = {
    Ê: " ",
    Ô: "'",
    Õ: "'",
    Ž: "é",
  }

  const regex = new RegExp(
    Object.keys(replacements)
      .map(str => new String(str).replace(/([.*+?^=!:${}()|[\]\/\\])/g, "\\$1"))
      .join("|"),
    "g"
  )

  const description = product?.descriptionHtml?.replace(regex, $0 => replacements[$0])
  const details = content?.details
  const process =
    content?.process || template?.process?.find(process => (process?.tag && process?.content ? product?.tags?.includes(process.tag) : null))?.content

  const [tab, setTab] = useState(description ? `description` : details ? `details` : `process`)
  const locales = useLanguage(`products`)
  Component.displayName = name
  return <Component description={description} details={details} locales={locales} process={process} setTab={setTab} tab={tab} />
}
