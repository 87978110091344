import React from "react"

import { useLanguage } from "../../../hooks/useLanguage"
import { useShopify } from "../../../hooks/useShopify"

export const withProductContentTimeline = Component => ({
  name = `ProductContentTimeline`,
  rushActive,
  rushFeeAdded,
  rushFeeOptionLimited,
  rushFeeProduct,
  setRushActive,
  setRushFee,
  setTimeline,
  setWeeks,
  tag,
  timeline,
  updateAddOn,
  variant,
  isPreorder,
}) => {
  const locales = useLanguage(`products`)
  const { formatTimeline } = useShopify()

  const formatedTimeline = formatTimeline(tag)

  Component.displayName = name
  return (
    <Component
      formatedTimeline={formatedTimeline}
      locales={locales}
      rushActive={rushActive}
      rushFeeAdded={rushFeeAdded}
      rushFeeOptionLimited={rushFeeOptionLimited}
      rushFeeProduct={rushFeeProduct}
      setRushActive={setRushActive}
      setRushFee={setRushFee}
      setTimeline={setTimeline}
      setWeeks={setWeeks}
      tag={tag}
      timeline={timeline}
      updateAddOn={updateAddOn}
      variant={variant}
      isPreorder={isPreorder}
    />
  )
}
