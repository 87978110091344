import React from "react"

import { NavContainer, NavBar, NavIconWrapper, PlayIcon } from "./ProductImageNavStyles"

interface Props {
  active: boolean
  activeImage: number
  images: Array<any>
  zoomed: boolean
}

export const ProductImageNav = ({ activeImage, images, zoomed, active }: Props) => (
  <NavContainer zoomed={zoomed} active={active}>
    {images.map(({ mediaContentType }, i) =>
      mediaContentType === "IMAGE" ? (
        <NavBar key={i} active={activeImage === i} />
      ) : (
        <NavIconWrapper key={i} active={activeImage === i}>
          <PlayIcon />
        </NavIconWrapper>
      )
    )}
  </NavContainer>
)
