import React from "react"

import { withProductUpsellRelated } from "./withProductUpsellRelated"
import { SearchItem } from "../../Search/Item/SearchItem"
import { Row } from "./ProductUpsellStyles"

interface Props {
  products: Array<any>
}

export const ProductUpsellRelated = withProductUpsellRelated(({ products }: Props) =>
  products?.length > 0 ? (
    <Row scrollHidden={`true`}>
      {products.map((product, index) => (
        <SearchItem key={product.handle} index={index} list={`Related Products`} product={product} width={`1/3`} screenWidth />
      ))}
    </Row>
  ) : null
)
