import React from "react"

import { useSanity } from "../../../hooks/useSanity"

export const withProductContentAdditional = Component => ({ name = `ProductContentAdditional`, active, content: page, setActive }) => {
  const { textNormaliser } = useSanity()

  const content = {
    ...page,
    content: textNormaliser(page?.content),
    accordions:
      page?.accordions?.length > 0
        ? [
            ...page.accordions.map(accordion => ({
              ...accordion,
              title: textNormaliser(accordion.title),
              content: textNormaliser(accordion.content),
            })),
          ]
        : [],
  }

  Component.displayName = name
  return <Component active={active} accordions={content?.accordions} content={content?.content} setActive={setActive} title={page?.title} />
}
