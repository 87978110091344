import React, { useEffect, useRef } from "react"
import { differenceInWeeks } from "date-fns"

import { useApp } from "../../../hooks/useApp"
import { useLanguage } from "../../../hooks/useLanguage"

export const withProductContentRushCalculator = Component => ({
  name = `ProductContentRushCalculator`,
  active,
  buttonText,
  disabledButtonText,
  rushFeeProduct,
  simple,
  setRushFee,
  setRushActive,
  setWeeks,
  status,
  setTimeline,
  timeline,
  trackedTimeline,
  updateAddOn,
}) => {
  const {
    config: {
      settings: { products },
    },
  } = useApp()
  const locales = useLanguage(`products`)
  const fieldRef = useRef()

  const handleCalculate = () => {
    const dateString = Object.entries(timeline)
      .map(entry => entry[1])
      .reduce((date, item, i) => (i ? `${date}-${item}` : item), ``)
      .split("-")
      .reverse()
      .join("-")

    const date = Date.parse(dateString)
    const now = Date.now()
    const weeksUntilWedding = differenceInWeeks(date, now)

    if (typeof weeksUntilWedding === `number`) {
      setWeeks(weeksUntilWedding)
      if (simple) {
        setRushFee(true)
      }
    }
  }

  const handleUpdate = async () => {
    if (status === `Within` && rushFeeProduct) {
      updateAddOn(rushFeeProduct)
    }

    if (status === `Before`) {
      const date = { ...timeline }

      Object.entries(date).map(([key]) => {
        date[key] = ``
        return null
      })
      setTimeline(date)

      fieldRef.current?.select()
    } else {
      setRushFee(false)
    }
  }

  const validateTimeline = () => {
    const completed = products.delivery.fields.filter(field => timeline[field.name] && timeline[field.name] !== ``)

    return {
      changed: trackedTimeline !== timeline,
      valid: completed.length === products.delivery.fields.length,
    }
  }

  useEffect(() => {
    if (active && simple) {
      setTimeout(() => {
        fieldRef.current?.focus()
      }, 100)
    }
  }, [active])

  Component.displayName = name
  return active || !simple ? (
    <Component
      buttonText={buttonText}
      disabledButtonText={disabledButtonText}
      fieldRef={fieldRef}
      locales={locales}
      handleCalculate={handleCalculate}
      handleUpdate={handleUpdate}
      products={products}
      setRushActive={setRushActive}
      setTimeline={setTimeline}
      simple={simple}
      timeline={timeline}
      validateTimeline={validateTimeline}
    />
  ) : null
}
