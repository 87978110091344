import React from "react"
import { navigate } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useRoutes } from "../../../hooks/useRoutes"

export const withProductFormGiftCard = Component => ({ name = `ProductFormGiftCard`, label, product }) => {
  const {
    config: { settings },
  } = useApp()
  const { linkResolver } = useRoutes()

  const { option, type } = settings.products.giftCards

  const currentOption = option.values.find(value => product.handle.includes(value.label.toLowerCase()))

  const handleOptionChange = value => {
    const opt = option.values.find(({ label }) => label === value)

    if (opt.handle !== product.handle) {
      navigate(linkResolver({ handle: opt.handle }, `product`)?.url)
    }
  }

  Component.displayName = name
  return (
    <Component currentOption={currentOption} handleOptionChange={handleOptionChange} label={label} option={option} product={product} type={type} />
  )
}
