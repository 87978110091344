import React, { useEffect, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"
import { useLanguage } from "../../../hooks/useLanguage"
import { useShopify } from "../../../hooks/useShopify"

export const withProductUpsell = Component => ({ name = `ProductUpsell`, product, recommendations, related }) => {
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { isBrowser, storage },
  } = useCore()
  const { isHidden } = useShopify()
  const locales = useLanguage(`products`)

  const handleRecentlyViewed = (product, keys) => {
    const recentlySaved = isBrowser && storage.get(keys.recentProducts) ? storage.get(keys.recentProducts) : []
    const handles = recentlySaved.filter(item => item && item !== product?.handle)

    if (!isHidden(product)) {
      handles.push(product?.handle)
    }

    if (isBrowser) {
      storage.set(keys.recentProducts, handles.slice(-4))
    }

    return handles.filter(item => item !== product?.shopify?.handle)
  }

  const recent = handleRecentlyViewed(product, keys)
  const [active, setActive] = useState(recent?.length > 0 ? `recent` : `related`)

  useEffect(() => {
    if (active !== `recommendations` && recommendations?.length > 0) {
      setActive(`recommendations`)
    }
  }, [recommendations?.length])

  Component.displayName = name
  return <Component active={active} locales={locales} recent={recent} recommendations={recommendations} related={related} setActive={setActive} />
}
