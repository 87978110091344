import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useLanguage } from "../../../hooks/useLanguage"

export const withProductContentRushFee = Component => ({
  name = `ProductContentRushFee`,
  active,
  product,
  rushFeeProduct,
  setRushFee,
  setTimeline,
  setWeeks,
  timeline,
  trackedTimeline,
  updateAddOn,
  weeks,
}) => {
  const {
    config: {
      settings: { products },
    },
  } = useApp()
  const locales = useLanguage(`products`)

  const status =
    weeks >= products.delivery.rush.min && weeks <= products.delivery.rush.max ? `Within` : weeks < products.delivery.rush.min ? `Before` : `After`
  const localeContent = `rushFeeCalculator${status}`

  Component.displayName = name
  return (
    <Component
      active={active}
      locales={locales}
      localeContent={localeContent}
      product={product}
      rushFeeProduct={rushFeeProduct}
      setRushFee={setRushFee}
      setTimeline={setTimeline}
      setWeeks={setWeeks}
      status={status}
      timeline={timeline}
      trackedTimeline={trackedTimeline}
      updateAddOn={updateAddOn}
    />
  )
}
