import React from "react"
import { format } from "date-fns"

import { useCore } from "../../../hooks/useCore"

export const withProductFormAttributes = Component => ({
  name = `ProductFormAttributes`,
  attributes,
  customAttributes,
  wholesaleCustomisations,
  setCustomAttributes,
  setCurrentShippingDate,
}) => {
  const {
    helpers: { handleize },
  } = useCore()

  const validateDate = input => {
    const currentDate = format(new Date(), `yyyy-MM-dd`)
    const userDate = format(new Date(input || currentDate), `yyyy-MM-dd`)

    const isDateInFuture = currentDate < userDate

    return isDateInFuture ? input : null
  }

  const handleAttributeChange = event => {
    const attribute = {}
    attribute[event?.target?.name] = event?.target?.type === "date" ? validateDate(event?.target?.value) : event?.target?.value

    if (attribute["requested-shipping-date-standard-16-weeks"] && attribute["requested-shipping-date-standard-16-weeks"] != null) {
      setCurrentShippingDate(attribute)
    } else if (event?.target?.name === "requested-shipping-date-standard-16-weeks" && attribute["requested-shipping-date-standard-16-weeks"] == null) {
      delete attribute["requested-shipping-date-standard-16-weeks"]
      delete customAttributes["requested-shipping-date-standard-16-weeks"]
      setCurrentShippingDate(attribute)
    }

    const attributes = {
      ...customAttributes,
      ...attribute,
    }

    setCustomAttributes(attributes)
  }

  const mergedAttributes = [...attributes, ...wholesaleCustomisations]

  Component.displayName = name
  return (
    <Component
      attributes={mergedAttributes}
      customAttributes={customAttributes}
      handleAttributeChange={handleAttributeChange}
      handleize={handleize}
    />
  )
}
