import React from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useShopify } from "../hooks/useShopify"
import { useQueries } from "../hooks/useQueries"

import { Product as Template } from "../components/Product/Product"

export const query = graphql`
  query($handle: String!, $parentQuery: String) {
    product: sanityProduct(shopify: { shopifyHandle: { eq: $handle }, shopifyDeleted: { ne: true } }) {
      ...ProductFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      _rawDetails(resolveReferences: { maxDepth: 10 })
      _rawProcess(resolveReferences: { maxDepth: 10 })
      _rawShopify(resolveReferences: { maxDepth: 10 })
      completeTheLook {
        shopify {
          handle: shopifyHandle
        }
      }
      primaryCollection {
        shopify {
          handle: shopifyHandle
        }
        title
      }
    }
    grouped: allSanityProduct(filter: { tags: { eq: $parentQuery } }) {
      nodes {
        ...ProductFragment
      }
    }
    template: sanityProductDetailsPage {
      _rawProcess(resolveReferences: { maxDepth: 10 })
      _rawSizeGuide(resolveReferences: { maxDepth: 10 })
      enquiryLink: _rawEnquiryLink(resolveReferences: { maxDepth: 3 })
      enquiryTag
    }
  }
  fragment ProductFragment on SanityProduct {
    id
    title
    vendor
    productType
    descriptionHtml
    tags
    image
    shopify {
      id: shopifyId
      handle: shopifyHandle
    }
  }
`

export default ({ data, ...props }) => {
  const {
    helpers: { encodeShopifyId, isBrowser },
  } = useCore()

  const {
    queries: { GET_PRODUCT_COMPLETE }
  } = useQueries()

  const { useQuery } = useShopify()
  const { product } = data

  const { data: live, loading, error } =
    isBrowser && product?.shopify?.handle?.length
      ? useQuery(GET_PRODUCT_COMPLETE, {
          fetchPolicy: `cache-and-network`,
          variables: {
            id: encodeShopifyId(product.shopify.id, `Product`),
            handle: product.shopify.handle,
            parentQuery: `tag:"parent:${product.shopify.handle}"`,
            firstCollections: 5,
            firstImages: 5,
            firstMedia: 5,
            firstMetafields: 10,
            firstVariants: 100,
          },
        })
      : {}

  if (error) console.error(error)

  return <Template {...props} {...data} live={live} loading={loading} />
}
