import React from "react"

import { Product } from "../../../../types"

import { withProductContentTimeline } from "./withProductContentTimeline"
import { ProductContentRushCalculator } from "./ProductContentRushCalculator"
import { TimelineButton, TimelineLabel, Indicator, Wrapper, NotificationBox, NotificationHeader, NotificationTitle } from "./ProductContentStyles"

interface Props {
  formatedTimeline: any
  locales: any
  rushActive: boolean
  rushFeeAdded: boolean
  rushFeeProduct: Product
  setRushActive: any
  setRush: any
  setRushFee: any
  setTimeline: any
  setWeeks: any
  tag: string
  timeline: any
  updateAddOn: any
  rushFeeOptionLimited: boolean
  variant: any
  isPreorder: boolean
}

export const ProductContentTimeline = withProductContentTimeline(
  ({
    formatedTimeline,
    locales,
    rushActive,
    rushFeeAdded,
    rushFeeProduct,
    setRushActive,
    setRushFee,
    setTimeline,
    setWeeks,
    tag,
    timeline,
    updateAddOn,
    rushFeeOptionLimited,
    variant,
    isPreorder,
  }: Props) =>
    locales.deliveryTimeline && tag && variant?.quantityAvailable < 1 ? (
      <>
        <Wrapper>
          <Indicator />
          <TimelineLabel>{`${locales.deliveryTimeline} ${formatedTimeline}`}</TimelineLabel>
        </Wrapper>

        {locales.rushFeeButton && locales.rushFeeLabel && setRushActive ? (
          <Wrapper>
            <TimelineLabel>{rushFeeAdded ? locales.rushFeeCalculatorAddedTitle : locales.rushFeeLabel}</TimelineLabel>
            <TimelineButton as={`button`} onClick={() => (rushFeeAdded ? updateAddOn(rushFeeProduct) : setRushActive(!rushActive))}>
              {rushFeeAdded ? locales.rushFeeCalculatorAddedButton : locales.rushFeeButton}
            </TimelineButton>
          </Wrapper>
        ) : null}

        <ProductContentRushCalculator
          active={rushActive}
          buttonText={locales.rushFeeCalculatorButton}
          simple
          timeline={timeline}
          setTimeline={setTimeline}
          setRushFee={setRushFee}
          setRushActive={setRushActive}
          setWeeks={setWeeks}
        />

        {rushFeeAdded && rushFeeOptionLimited ? (
          <NotificationBox>
            <NotificationHeader>
              <NotificationTitle>{`When Rush Fee is applied, only the 2 month option is available for onedayPAY.`}</NotificationTitle>
            </NotificationHeader>
          </NotificationBox>
        ) : null}
      </>
    ) : isPreorder ? (
      <Wrapper>
        <Indicator active />
        <TimelineLabel>Pre-order</TimelineLabel>
      </Wrapper>
    ) : locales.readyToShip && variant?.quantityAvailable > 0 ? (
      <Wrapper>
        <Indicator active />
        <TimelineLabel>{locales.readyToShip}</TimelineLabel>
      </Wrapper>
    ) : null
)
