import React from "react"

import { useLanguage } from "../../../hooks/useLanguage"

export const withProductContentSizeGuideButton = Component => ({ name = `ProductContentSizeGuideButton`, setActive, sizeChart }) => {
  const locales = useLanguage(`products`)

  Component.displayName = name
  return <Component locales={locales} setActive={setActive} sizeChart={sizeChart} />
}
