import React from "react"
import { isTaggedTemplateExpression } from "typescript"

import { useApp } from "../../../hooks/useApp"
import { useLanguage } from "../../../hooks/useLanguage"
import { useShopify } from "../../../hooks/useShopify"

export const withProductFormInputs = Component => ({
  name = `ProductFormInputs`,
  adding,
  addOns,
  handleAddToCart,
  handleQuantityChange,
  customAttributes,
  product,
  quantity,
  setNotify,
  trackedCustomAttributes,
  variantSelected,
  variant,
}) => {
  const {
    config: { settings },
  } = useApp()
  const { formatMoney } = useShopify()
  const locales = {
    ...useLanguage(`cart`),
    ...useLanguage(`products`),
  }
  const validAddons = addOns.filter(addon => Boolean(addon))

  const productPrice = variant?.priceV2?.amount ? Number(variant?.priceV2?.amount) : 0
  const addOnsPrice = validAddons.reduce(
    (price, addOn, i) => (i ? price + parseInt(addOn?.variants[0]?.priceV2.amount || 0) : parseInt(addOn?.variants[0]?.priceV2.amount || 0)),
    0
  )

  const variantSoldOut = variantSelected && !variant?.availableForSale
  const isPreorder = product.tags.filter(tag => tag.includes(`${settings.products.purchaseTag}Pre-order`)).length > 0
  const buttonText = adding
    ? locales.addingToCart
    : variantSoldOut
      ? locales.variantSoldOut
      : isPreorder
        ? "Pre-order"
        : addOnsPrice > 0
          ? `${locales.addToCart} – ${formatMoney(productPrice + addOnsPrice)}`
          : locales.addToCart

  Component.displayName = name
  return (
    <Component
      adding={adding}
      buttonText={buttonText}
      customAttributes={customAttributes}
      handleAddToCart={handleAddToCart}
      handleQuantityChange={handleQuantityChange}
      locales={locales}
      product={product}
      quantity={quantity}
      settings={settings}
      setNotify={setNotify}
      trackedCustomAttributes={trackedCustomAttributes}
      variantSelected={variantSelected}
      variantSoldOut={variantSoldOut}
    />
  )
}
