import React, { useEffect, useState } from "react"

import { useLanguage } from "../../../hooks/useLanguage"
import { useLocation } from "../../../hooks/useLocation"
import { useShopify } from "../../../hooks/useShopify"

const DAY_MILLISECONDS = 1000 * 60 * 60 * 24
const DAYS_12_WEEKS = 84
const RUSH_DATE_HANDLE = "rush-order-under-12-weeks"

export const withProductFormAddOns = Component => ({
  name = `ProductFormAddOns`,
  active,
  setActive,
  addOns,
  updateAddOn,
  product,
  currentShippingDate,
}) => {
  const { useProducts } = useShopify()
  const locales = useLanguage(`products`)
  const { storeConfig } = useLocation()
  const [checkedAddOns, setCheckedAddOns] = useState<string[]>([])
  const [rushCheckDisabler, setRushCheckDisabler] = useState(false)
  const hasRushAddOn = addOns?.find(addOn => addOn.handle === RUSH_DATE_HANDLE)

  const handles = [...product?.tags?.filter((tag: string) => tag.includes(`add:`)).map((tag: string) => tag.replace(`add:`, ``))].sort() || []
  const products = useProducts({ handles, firstVariants: 1 })
  let totalDaysUntilShipped: any = undefined
  let shipDateHold: any

  useEffect(() => {
    if (currentShippingDate != undefined && products.length) {
      const rushDateProduct = products.find(addon => addon.handle === RUSH_DATE_HANDLE)

      if (rushDateProduct) {
        let currentDate: any = new Date()
        let shipDate: any = new Date(currentShippingDate["requested-shipping-date-standard-16-weeks"])
        shipDateHold = shipDate

        totalDaysUntilShipped = Math.round(Math.abs(shipDate - currentDate) / DAY_MILLISECONDS)

        if (totalDaysUntilShipped <= DAYS_12_WEEKS && !hasRushAddOn) {
          toggleCheckboxOn(RUSH_DATE_HANDLE)
          setRushCheckDisabler(true)
          updateAddOn(rushDateProduct)
        } else if (totalDaysUntilShipped > DAYS_12_WEEKS && hasRushAddOn) {
          toggleCheckboxOff(RUSH_DATE_HANDLE)
          setRushCheckDisabler(false)
          updateAddOn(rushDateProduct)
        }
      }
    }
  }, [currentShippingDate, products, checkedAddOns])

  const toggleCheckboxOn = (handle: string): string[] => [...checkedAddOns, handle]
  const toggleCheckboxOff = (handle: string): string[] => checkedAddOns.filter(addOn => addOn !== handle)

  const updateRushAndAddon = item => {
    updateAddOn(item)
    setCheckedAddOns(checkedAddOns?.includes(item.handle) ? toggleCheckboxOff(item.handle) : toggleCheckboxOn(item.handle))
  }

  // Remove "s" suffix for singular gown
  let productType = product?.productType ? product.productType.toLowerCase() : ""
  if (productType === "gowns") productType = "gown"

  Component.displayName = name
  return (
    <Component
      active={active}
      locales={locales}
      products={products}
      productType={productType}
      setActive={setActive}
      updateAddOn={updateAddOn}
      wholesale={storeConfig?.wholesale}
      updateRushAndAddon={updateRushAndAddon}
      checkedAddOns={checkedAddOns}
      rushCheckDisabler={rushCheckDisabler}
    />
  )
}
