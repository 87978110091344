import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../../Styled/Container"

export const Upsells = tw(Container)`mt-7 mb-9-6 md:my-12 max-w-lg px-0`
export const UpsellsContent = styled.div`
  ${props => (props.active ? tw`block` : tw`hidden`)}
`

export const Row = styled.div`
  ${tw`flex items-center justify-start md:-mx-0-6 mt-2-4`}
  ${props => (props.scrollHidden ? tw`pl-1-2 md:pl-0 overflow-y-hidden overflow-x-auto md:overflow-x-hidden` : ``)}
`
